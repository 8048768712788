// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDTExDrqqYgJg3wMq5fXHyvcmUY526uGMs",
    authDomain: "maze-98a17.firebaseapp.com",
    databaseURL: "https://maze-98a17.firebaseio.com",
    projectId: "maze-98a17",
    storageBucket: "maze-98a17.appspot.com",
    messagingSenderId: "237355769347"
  },
  analytics: {
    id: 'UA-135058791-1'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
