import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomePageModule' },
  { path: 'gen', loadChildren: './gen/gen.module#GenPageModule' },
  { path: 'img', loadChildren: './img/img.module#ImgPageModule' },
  { path: 'str', loadChildren: './str/str.module#StrPageModule' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
